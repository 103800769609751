import './Github.css';
import React from "react";
import jam from "./jammockup2.png";
import polldb from "./PollDBMockup.png";
import squealer from "./mockupsquealer.png";

function Github() {
    React.useEffect(() => {
        const options = {
            rootMargin: '-20% 0px -30% 0px',
            threshold: 0 // half of item height
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                console.log(entry);
                if(entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show')
                }
            });
        }, options);
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
        hiddenElements.forEach((el) => console.log(el));
    }, [])
    return(
        <div className='container-fluid col-11 col-lg-7 mt-5 hidden more-mar-bottom'>
            <div className='row'>
                <div className='col-12 rounded-5 p-lg-5 p-4 github-card'>
                    <p className='fw-bold text-white fs-3'>My Portfolio 💼</p>
                    <div id="carouselExampleCaptions" className="carousel slide">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={jam} className="d-block w-100" alt="..."/>
                                <div className="carousel-caption d-block position-static">
                                    <h5 className="fw-bold text-white fs-5">JAM: Just Analyze Mobility</h5>
                                    <p className="fs-6 p-info">A smart parking application leveraging the IoT protocol MQTT, specifically focusing on a spatially aware variant known as LA-MQTT. Throughout the development process, the protocol underwent numerous modifications to enhance its scalability by enabling multi-broker support. This project served as the focal point of my bachelor's thesis.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={polldb} className="d-block w-100" alt="..."/>
                                <div className="carousel-caption d-block position-static">
                                    <h5 className="fw-bold text-white fs-5">PollDB</h5>
                                    <p className="fs-6 p-info">A platform designed for enterprises and their customers,
                                        facilitating the creation of polls, setting rewards, and analyzing graphical
                                        representations of poll results. The entire project is developed using PHP,
                                        MySQL and MongoDB, with a primary focus on establishing a robust relational
                                        database structure.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={squealer} className="d-block w-100" alt="..."/>
                                <div className="carousel-caption d-block position-static">
                                    <h5 className="fw-bold text-white fs-5">Squealer</h5>
                                    <p className="fs-6 p-info">A social network poised to replace Twitter following its
                                        evolution into X. This platform enables users to share text messages,
                                        geolocations, and images across various channels and chats. The character limit
                                        for messages (squeals) is determined by a daily allocation influenced by user reactions. The graphic interface is fully responsive, ensuring a seamless user experience.</p>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev align-items-start align-items-md-center" type="button"
                                data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon mt-5 mt-md-0" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next align-items-start align-items-md-center" type="button"
                                data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon mt-5 mt-md-0" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <i className="bi bi-github github-icon"></i>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <a href='https://github.com/AndreaIannoli' target='_blank'
                                   className='btn btn-secondary rounded-5 github-btn fs-5 fw-semibold'>
                                    <i className="bi bi-github me-2"></i>
                                    Go to my GitHub
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Github;
