import './Presentation.css';
import propic from './andrea-iannoli.jpg';
import ScrollReveal from "scrollreveal";
import React from "react";

function Presentation() {
    React.useEffect(() => {
        const options = {
            rootMargin: '-20% 0px -30% 0px',
            threshold: 0 // half of item height
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                console.log(entry);
                if(entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show')
                }
            });
        }, options);
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
        hiddenElements.forEach((el) => console.log(el));
    }, [])
    return(
        <div className='container-fluid'>
            <div className='row gap-3 gap-lg-5 justify-content-center align-items-center hidden'>
                <div className='col-8 col-lg-3 col-md-6 col-sm-6 d-flex align-items-center justify-content-center flex-column'>
                    <img className='rounded-circle img-fluid' src={propic} id='propic'/>
                    <div className="d-flex gap-3">
                        <a href='https://www.linkedin.com/in/andrea-iannoli' target='_blank'
                           className='btn btn-secondary github-btn fs-5 fw-semibold rounded-circle'>
                            <i className="bi bi-linkedin"></i>
                        </a>
                        <a href='https://github.com/AndreaIannoli' target='_blank'
                           className='btn btn-secondary github-btn fs-5 fw-semibold rounded-circle'>
                            <i className="bi bi-github"></i>
                        </a>
                    </div>
                </div>
                <div className='col-11 col-lg-7 col-md-11 col-sm-12 rounded-5 p-lg-5 p-4' id='card-presentation'>
                    <p className='fw-bold text-white fs-3'>Who am I?</p>
                    <p className='fs-4 p-info'>
                        I am Computer Science student 📚. <br/> <br/>
                        I'm currently attending a Master of Science program in Computer Science and seeking new opportunities.
                        <br/><br/>
                        I’m hands on in all stages of the software development cycle during my study and work, from
                        planning and design to testing and deployment. No two days are the same for me 😱;
                        <br/><br/>
                        But let's take a look at my last few years experiences...
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Presentation;
