import './Timeline.css';
import uniSeal from './BOLOGNA-VETTORIALE-MIO_BIANCO.png';
import jeboLogo from './logo-jebo.png';
import eutechLogo from './EuTech-logo.png';
import React from "react";

function Timeline() {
    React.useEffect(() => {
        const options = {
            rootMargin: '-20% 0px -30% 0px',
            threshold: 0 // half of item height
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                console.log(entry);
                if(entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show')
                }
            });
        }, options);
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
        hiddenElements.forEach((el) => console.log(el));
    }, [])
    return(
        <div className='container-fluid' id='timeline-container'>
            <div className='row justify-content-center'>
                <div className='col-lg-5 col-9 d-flex justify-content-end hidden' id='timeline-card-container-0'>
                    <div
                        className='col-5 col-lg-7 col-md-12 col-sm-12 rounded-5 p-4 p-lg-5 timeline-card timeline-card-l'>
                        <div className='d-flex justify-content-center'>
                            <img src={uniSeal} className='img-fluid' style={{width: 80 + '%'}}/>
                        </div>
                        <hr style={{color: 'white'}}/>
                        <p className='fs-5 p-info'>
                            I started my bachelor in Computer Science at the University of Bologna. Since the first
                            moment, I loved the academic environment of Bologna, which helped me stay focused and work
                            on my professional education by studying what I love the most Computer Science.
                        </p>
                    </div>
                </div>
                <div className='col-1 d-flex justify-content-center mx-2' style={{width: "fit-content"}}>
                    <div className='line always-show'>
                        <div className='dot rounded-circle'></div>
                    </div>
                </div>
                <div className='col-1 col-lg-5 p-0 hidden' id='timeline-date-0'>
                    <div className="ms-0 text-white fs-6">september 2020 - december 2023</div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-1 col-lg-5 p-0 order-2 order-lg-0 hidden' id='timeline-date-1'>
                    <div className="ms-0 text-white fs-6 text-end">may 2022 - june 2023</div>
                </div>
                <div className='col-1 order-1 order-lg-1 d-flex justify-content-center mx-2'
                     style={{width: "fit-content"}}>
                    <div className='line always-show'>
                        <div className='dot rounded-circle' id='timeline-dot-1'></div>
                    </div>
                </div>
                <div className='col-lg-5 col-9 order-0 order-lg-2 d-flex justify-content-start hidden'
                     id='timeline-card-container-1'>
                    <div
                        className='col-5 col-lg-7 col-md-12 col-sm-12 rounded-5 p-4 p-lg-5 timeline-card timeline-card-r'>
                        <div className='d-flex justify-content-center'>
                            <img src={jeboLogo} className='img-fluid' style={{width: 30 + '%'}}/>
                        </div>
                        <hr style={{color: 'white'}}/>
                        <p className='fs-5 p-info'>
                            I was part of JEBO, a junior enterprise, where I became Head of IT. Working with customers
                            as a team helped me grow. When they offered me the position of Head of IT, I didn't
                            hesitate.
                        </p>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-lg-5 col-9 d-flex justify-content-end timeline-card-container-2 hidden'
                     id='timeline-card-container-2'>
                    <div
                        className='col-5 col-lg-7 col-md-12 col-sm-12 rounded-5 p-4 p-lg-5 timeline-card timeline-card-l-2'>
                        <div className='d-flex justify-content-center'>
                            <img src={eutechLogo} className='img-fluid' style={{width: 40 + '%'}}/>
                        </div>
                        <hr style={{color: 'white'}}/>
                        <p className='fs-5 p-info'>
                            I had the opportunity to complete an internship as a software developer at Eutech Engineers
                            in Dublin. It was my second experience abroad in which I had the opportunity to discover a
                            beautiful and inspiring environment like Dublin. I used to work sometimes in the Trinity
                            College coworking space, and hanging around that place always picked me up and set me in the
                            right mood.
                        </p>
                    </div>
                </div>
                <div className='col-1 d-flex justify-content-center mx-2' style={{width: "fit-content"}}>
                    <div className='line always-show'>
                        <div className='dot rounded-circle' id='timeline-dot-2'></div>
                    </div>
                </div>
                <div className='col-1 col-lg-5 p-0 hidden' id='timeline-date-2'>
                    <div className="ms-0 text-white fs-6">september 2022 - december 2022</div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-1 col-lg-5 p-0 order-2 order-lg-0 hidden' id='timeline-date-3'>
                    <div className="ms-0 text-white fs-6 text-end">september 2023 - now</div>
                </div>
                <div className='col-1 order-1 order-lg-1 d-flex justify-content-center mx-2'
                     style={{width: "fit-content"}}>
                    <div className='line always-show'>
                        <div className='dot rounded-circle' id='timeline-dot-1'></div>
                    </div>
                </div>
                <div className='col-lg-5 col-9 order-0 order-lg-2 d-flex justify-content-start hidden'
                     id='timeline-card-container-1'>
                    <div
                        className='col-5 col-lg-7 col-md-12 col-sm-12 rounded-5 p-4 p-lg-5 timeline-card timeline-card-r'>
                        <div className='d-flex justify-content-center'>
                            <img src={uniSeal} className='img-fluid' style={{width: 80 + '%'}}/>
                        </div>
                        <hr style={{color: 'white'}}/>
                        <p className='fs-5 p-info'>
                            I embarked on the enriching journey of pursuing a Master of Science degree in Computer Science at the esteemed University of Bologna. This pivotal step in my academic and professional trajectory signifies a commitment to deepening my understanding of cutting-edge concepts and methodologies within the computer science's field.
                        </p>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-lg-5 col-9'>
                </div>
                <div className='col-1 d-flex justify-content-center mx-2' style={{width: "fit-content"}}>
                    <div className='line line-last'>
                        <div className='dot rounded-circle' style={{marginTop: 150 + 'px'}}></div>
                    </div>
                </div>
                <div className='col-1 col-lg-5'>
                </div>
            </div>
        </div>
    )
}

export default Timeline;
