import './HeadingButtons.css';
import ScrollReveal from "scrollreveal";

function HeadingButtons() {
    ScrollReveal().reveal('.btn', { delay: 2550 });
    return(
        <div className='d-flex justify-content-center mt-5'>
            <div className='col-md-3 col-lg-2 d-flex flex-column justify-content-center gap-3'>
                <a className='btn btn-primary' id='contactMe' href='#contact'>Contact Me<i className="bi bi-envelope-fill ms-2"></i></a>
            </div>
        </div>
    );
}

export default HeadingButtons;