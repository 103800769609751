import React from 'react';
import ReactDOM from 'react-dom/client';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
// JQuery
import $ from 'jquery';
// Popper
import Popper from 'popper.js';

import './index.css';
import Heading from "./Heading";
import HeadingButtons from "./HeadingButtons";
import ScrollDown from "./ScrollDown";
import reportWebVitals from './reportWebVitals';
import '/node_modules/reveal.js/dist/reveal.css';
import '/node_modules/reveal.js/dist/theme/black.css';
import Presentation from "./Presentation";
import Timeline from "./Timeline";
import Skills from "./Skills";
import Github from "./Github";
import Contact from "./Contact";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className='container-fluid'>
        <div className='row align-items-center' id='box1'>
            <div className='col align-self-center'>
                <Heading />
                <HeadingButtons />
                <ScrollDown />
            </div>
        </div>
    </div>
    <Presentation />
    <Timeline />
    <Skills />
    <Github />
    <Contact />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
