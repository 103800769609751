import './Heading.css';
import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom/client';
import TypeIt from "typeit-react";
import animoji from './animoji-def1.gif';
import SuperGif from 'libgif'
import video from './animoji.MOV'
import Presentation from "./Presentation";
import Timeline from "./Timeline";
import Skills from "./Skills";
import Github from "./Github";
import Contact from "./Contact";

function Heading() {
    const [animojiState, setAnimojiState] = useState(false);

    function onAnimationEnd(animojiEl) {
        console.log("animation log setted", animojiEl.get_canvas());
        try {
            // Define the event listener function
            function handleAnimationEnd() {
                console.log("Animation end triggered");
                animojiEl.move_to(0);
                console.log("Removing animationend event listener");
                animojiEl.get_canvas().removeEventListener("animationend", handleAnimationEnd);
                animojiEl.get_canvas().classList.remove('animojiAnimation');
                const reflow = animojiEl.get_canvas().offsetHeight;  // Trigger reflow
                animojiEl.get_canvas().classList.add('animojiAnimation');
                // You can also call the function again if needed
                onAnimationEnd(animojiEl);
            }

            // Add the event listener
            animojiEl.get_canvas().addEventListener("animationend", handleAnimationEnd);
        } catch (error) {
            console.error("Error in event listener:", error);
        }
    }


    useEffect(() => {
        var imgTags = document.getElementsByTagName('img');
        Array.from(imgTags).forEach(function (img_tag) {
            if (/.*\.gif/.test(img_tag.src)) {

                let rub = new SuperGif({ gif: img_tag });

                rub.load(function(){
                    console.log('oh hey, now the gif is loaded');
                    rub.get_canvas().id = "animoji";
                    rub.get_canvas().classList.add('animojiAnimation');
                    onAnimationEnd(rub);
                    document.getElementsByClassName("jsgif").item(0).style.display = "inline";
                });
            }
        });
    }, [animojiState]);

    return (
        <div className='container-fluid d-flex align-items-center justify-content-center flex-column mt-5' id='headingsContainer'>
            {animojiState ?
                <img src={animoji}
                     width="300" height="225"/>
                : null}
            <h1 className='display-2 fw-semibold text-center col-12 col-lg-9' id='mainHeading'>
                <TypeIt
                    options={{
                    strings: ["Hi!<span id='waving'/> My name is <b style='color: var(--color-primary-100)'>Andrea</b>.", "<span style='color: var(--color-primary-100)'>I'm a MSc Student in CS</span>."],
                    speed: 50,
                    waitUntilVisible: true,
                    afterComplete: function (instance) {
                        setTimeout(function () {
                            instance.destroy();
                            document.getElementById('subheading').classList.add('revealed');
                            setTimeout(function () {
                                document.getElementById('contactMe').classList.add('revealed');
                            }, 2000);
                            setTimeout(function () {
                                document.getElementById('scrollDown').classList.add('revealed-slidedown');
                                /*
                                let animojiElement = document.createElement('img');
                                animojiElement.src = animoji;
                                animojiElement.id = 'animoji';


                                 */
                                setAnimojiState(true);

                                /*
                                document.getElementById('headingsContainer').insertBefore(animojiElement, document.getElementById('mainHeading'));
                                document.getElementById('animoji').addEventListener('animationiteration', function () {
                                    document.getElementById('animoji').src = animoji;
                                });
                                */

                            }, 2500);
                            }, 2000);
                    }
                }}
            /></h1>
            <h2 className='display-5 fw-semibold text-center' id='subheading'>*and a guy who has fallen in love with that</h2>
        </div>
    );
}
export default Heading;
