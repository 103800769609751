import './Skills.css';
import React from "react";
import htmlLogo from './html.png';
import jsLogo from './javascript.png';
import cssLogo from './css.png';
import pythonLogo from './python.png';
import javaLogo from './java.png';
import rLogo from './r.png';
import matlabLogo from './matlab.png';
import mysqlLogo from './mysql.png';
import mongodbLogo from './mongodb.png';
import phpLogo from './php.png';

function Skills() {
    React.useEffect(() => {
        const options = {
            rootMargin: '-20% 0px -30% 0px',
            threshold: 0 // half of item height
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                console.log(entry);
                if(entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show')
                }
            });
        }, options);
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
        hiddenElements.forEach((el) => console.log(el));
    }, [])
    return(
        <div className='container-fluid col-11 hidden mar-bottom'>
            <div className='row'>
                <div className='rounded-5 p-4 p-lg-5 skills-card'>
                    <p className='fw-bold text-white fs-3'>My skills 💪🏻</p>
                    <p className='fs-4 p-info'>
                        During my academic and work journey I learned and used a lot of languages and technologies. Here are some of them:
                    </p>
                    <div className='container'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={htmlLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={jsLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={cssLogo} className='language-logo'/>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={pythonLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={javaLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={rLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={matlabLogo} className='language-logo'/>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={mysqlLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={mongodbLogo} className='language-logo'/>
                            </div>
                            <div className='col-3 col-sm-3 col-md-2 col-lg-1'>
                                <img src={phpLogo} className='language-logo'/>
                            </div>
                        </div>
                    </div>
                    <p className='fs-4 p-info mt-4 mt-lg-5'>
                        But more important than that I've learned:
                        <div className='container mt-3 mt-lg-5'>
                            <div className='row gap-3 gap-lg-5 d-flex justify-content-center'>
                                <div className='col-sm-12 col-md-12 col-lg-5 rounded-5 p-4 p-lg-5 skills-inner-card hidden'>
                                    <i className="bi bi-people-fill fs-1"></i>
                                    <ul>
                                        <li>To work in a team and to coordinate it</li>
                                        <li>To deal with customers and to fulfill their needs</li>
                                        <li>To handle deadlines and deliver work and tasks on time</li>
                                        <li>To be enterprising</li>
                                        <li>To solve problems under pressure</li>
                                    </ul>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-5 rounded-5 p-4 p-lg-5 skills-inner-card hidden'>
                                    <i className="bi bi-gear-wide-connected fs-1"></i>
                                    <ul>
                                        <li>Data structure and algorithms</li>
                                        <li>To design scalable software</li>
                                        <li>To design and structure database</li>
                                        <li>To develop native and non-native mobile apps</li>
                                        <li>To use statistic and dataset to build Machine Learning models</li>
                                        <li>Cybersecurity fundamentals</li>
                                        <li>and more...</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </p>
                </div>
            </div>
        </div>
    )
}

export default Skills;
