import './ScrollDown.css';

function ScrollDown() {
    return(
        <div className='d-flex justify-content-center mt-4' id='scrollDown' style={{opacity: 0}}>
            <div className='container-sm'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-5 col-lg-4 p-0'>
                        <p className='fs-6 fw-bold text-center mb-0'>Scroll to know Me!</p>
                    </div>
                </div>
                <div className='row d-flex justify-content-center mt-0'>
                    <div className='col-1 d-flex justify-content-center'>
                        <i className="bi bi-chevron-compact-down fs-2" id='scrollDownIcon'></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScrollDown;