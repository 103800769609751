import './Contact.css';
import React from "react";
import emailLogo from "./email.webp";

function Contact() {

    return(
        <div className='container-fluid mt-5 mb-5' id='contact'>
            <div className='row justify-content-center'>
                <div className='col-11 rounded-5 px-4 px-lg-5 pb-4 pb-lg-5 pt-0 contact-card'>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className='col-5 col-md-2 col-lg-1' id="bubble-mail-container">
                            <div className="p-3 rounded-circle" id="bubble-mail">
                                <img className='img-fluid' src={emailLogo}/>
                            </div>

                        </div>
                    </div>
                    <p className='fw-bold text-white fs-3 z-3 mt-lg-0 mt-5'>Contact Me! 🚀</p>
                    <div className='row'>
                        <div className="col-12 col-lg-6 form-floating mb-3">
                            <input type="text" className="form-control email-input" id="floatingName"
                                   placeholder="Your full name..." onKeyUp={sendEmail}/>
                            <label htmlFor="floatingName" className='ms-2'>Your full name...</label>
                        </div>
                        <div className="col-12 col-lg-6 form-floating mb-3">
                            <input type="email" className="form-control email-input" id="floatingEmail"
                                   placeholder="name@example.com" onKeyUp={sendEmail}/>
                            <label htmlFor="floatingEmail" className='ms-2'>Your email address...</label>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control email-input" id="floatingSubject"
                               placeholder="Your message subject..." onKeyUp={sendEmail}/>
                        <label htmlFor="floatingSubject">Your message subject...</label>
                    </div>
                    <div className="form-floating">
                        <textarea className="form-control textarea-input" id="floatingTextarea"
                                  placeholder="Your message..." onKeyUp={sendEmail}/>
                        <label htmlFor="floatingTextarea">Your message...</label>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <a href='' enctype='text/html' id='send_email' className='btn btn-secondary send-btn mt-3 me-2'>Submit<i
                            className="ms-2 bi bi-send-fill"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function sendEmail() {
    var link = document.getElementById('send_email');
    var sender = document.getElementById('floatingEmail').value;
    var name = document.getElementById('floatingName').value;
    var subject = document.getElementById('floatingSubject').value;
    var message = sender + "%0AHello my name is " + name + ",%0A" + document.getElementById('floatingTextarea').value;
    var email = "andrea.iannoli01@gmail.com";
    var href = "mailto:" + email + "?subject=" + subject + "&body=" + message;
    console.log(href);
    link.setAttribute("href", href);
}

export default Contact;
